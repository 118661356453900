import React from "react"
import Helmet from "react-helmet"

function ApplicationPage () {

return (
<>

<div id="ff-compose"></div>
<Helmet>
<style>{`
    .ff-section {
        padding-top: 9em;
        background-image: url("https://res.cloudinary.com/mysnapshot/image/upload/v1642057964/Course%20Logos/mys-logo-big_jreq71.png");
        background-position: center top;
        background-repeat: no-repeat;
        background-size: 250px 70px; 
            }

    #Publish1FAIpQLSdkH2hOAG9ZDLBRmA5yjs4bivoCCSBqaZFfhLdTjTcVIgGdGg {
        padding-top: 3em; 
    }

    #gatsby-focus-wrapper {
        background-color: #D5DFEA !important;
    }

    #ff-title-998508102 {
        text-align: center;
        padding-bottom: 1em; 
        color: #344355 !important;
    }

    .ff-section h3 {
        color: #344355 !important;
        padding-bottom: 0.75em !important;
    }

    .ff-success {
        padding: 0px 20px; 
        font-size: 18px !important; 
        height: 100vh; 
        min-height: 100% !important;
    }

    .ff-form .ff-widget-error {
        color: #D63C33 !important;
    }

    .ff-form .ff-widget-error b {
        background-color: #D63C33 !important;
    }

    img {
        margin-bottom: 0em !important;
    }

    .btn-primary {
        background-color: #4B89DC !important;
        color: black !important;
        vertical-align: middle;
        align-items: center;
        text-transform: initial !important; 
    } 

    .btn-secondary {
        text-transform: initial !important;
        background-color: #6C7D91 !important;
        color: white !important;
    }

    .ff-description {
        font-size: 16px;
        text-align: center;
    }

    input {
        border: 1px solid #C1CCD9 !important;
        outline: none; 
    }

    textarea {
        border: 1px solid #C1CCD9 !important;
        outline: none; 
    }

    input:focus {
        border: 1px solid #4B89DC !important;
        background-color: #D5DFEA !important;  
      }

    .ff-button-bar {
        text-align: center;
        padding-bottom: 4em !important; 
    }

`}
</style>
<script async defer src="https://formfacade.com/include/105416567367288830990/form/1FAIpQLSdkH2hOAG9ZDLBRmA5yjs4bivoCCSBqaZFfhLdTjTcVIgGdGg/classic.js?div=ff-compose"></script>
</Helmet>
</>
)
} 

export default ApplicationPage
